import axios from '../config/Axios';
import {Alert} from './Functios';

export const logout = async () => {

  await apiFetchGet(`session-logout/${JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).user.id}`)

  localStorage.clear();

  window.location.href = '/'

}

export const apiFetchGet = async (endpoint, body = null) => {

  const response = await axios.get(`${endpoint}`, {
    params: body,
    headers: {
      Authorization: 'Bearer ' + JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).token
    }
  });
  return response;
}


export const apiFecthPost = async (endpoint, body) => {

  const response = await axios.post(`${endpoint}`, body, {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).token
    }
  })
  return response;
}


export const apiFetchPut = async (endpoint, body) => {

  const response = await axios.put(`${endpoint}`, body, {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).token
    }
  });

  if (response.data === true)

    return response
}


export const apiFetchDelete = async (endpoint, body = null) => {

  const response = await axios.delete(`${endpoint}`, {
    data: body,
    headers: {
      Authorization: 'Bearer ' + JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).token
    }
  });

  return response;

}

export const apiFecthPostExcel = async (endpoint, filename, body) => {

  const response = await axios.post(endpoint, body, {
    headers: {
      Authorization: 'Bearer ' + JSON.parse(JSON.parse(localStorage.getItem('persist:auth')).authentication).token
    },
    responseType: 'blob'
  }).then(response => {
    const fileName = `${filename}.xlsx`;
    
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(url);

  }).catch(error => {
    Alert(
      'info',
      'Ooops...',
      'Nenhum Registro Encontrado!'
    );
  });

  return response;
};