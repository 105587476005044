import { 
  createStore, 
  applyMiddleware 
} from 'redux';
import createSaga from 'redux-saga';
import { 
  persistStore, 
  persistReducer 
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './modules/rootReducer';
import rootSaga from './modules/rootSaga';

const persistConfig = {
  key: 'auth',
  storage: storage,
  whitelist: ['authentication', 'auth2']
}

const persistorReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSaga();

const middleware = applyMiddleware(sagaMiddleware);
const store = createStore(persistorReducer, middleware);

sagaMiddleware.run(rootSaga);
const persistor = persistStore(store);

export { persistor, store };