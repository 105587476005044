import {
    apiFecthPost,
    apiFetchGet,
    logout
} from './RequestResponse';


export const Send = async (form, text, responderId) => {

    try {
        const {
            id_company,
            id_sector,
            number_client,
            number_bot,
            name_client
        } = form;

        const result = await apiFecthPost('send-messages', {
            id_company,
            id_sector,
            number_client,
            number_bot,
            message: text,
            conversation_situation: "ATTENDANCE",
            name_client,
            id_msg_reply: responderId
        });

        return result;
    } catch (error) {
        console.log(error)
    }


}


export const SendGroup = async (form, text, responderId) => {

    try {
        const {
            id_company,
            id_sector,
            number_client,
            number_bot,
            id_group_external
        } = form;

        const result = await apiFecthPost('send-message-group', {
            id_company,
            id_sector,
            id_group_external,
            number_client,
            number_bot,
            message: text,
            url: '',
            id_msg_reply: responderId
        });

        return result;
    } catch (error) {
        console.log(error)
    }


}


export const SendNew = async ({ id_company, id_sector, number_bot, number_client, name_client, message }) => {


    await apiFecthPost('send-messages?newConversation=true', {
        id_company,
        id_sector,
        number_client: `55${number_client}`,
        number_bot,
        message,
        conversation_situation: "ATTENDANCE",
        name_client
    });
}



export const SendNewMessage = async (id_company, id_sector, number_client, number_bot, message) => {

    await apiFecthPost("send-messages", {
        id_company,
        id_sector,
        number_client,
        number_bot,
        message,
        conversation_situation: "ATTENDANCE",
        start_conversation: true
    });
}


export const SendFinishMessage = async () => {

    let messageOne = `*Atendimento finalizado pelo Atendente*

Agradecemos seu contato! Volte sempre que precisar

*Caso necessite de um novo atendimento inicie uma nova conversa e escolha a opção desejada na URA de atendimento, ou se preferir liguem em nosso 0800*
`

    let messageTwo = `*Atendimento finalizado pelo Atendente*

Agradecemos seu contato! Volte sempre que precisar

Ah! Antes de ir embora, deixe a sua opinião 
        
De *0* a *10*, qual nota você dá para o atendimento recebido?`;

    let messages = [messageOne, messageTwo]

    messages.map(async item => {
        await apiFecthPost("send-messages", {
            id_company: localStorage.getItem('@sac-v1.0.0-id_company'),
            id_sector: localStorage.getItem('@sac-v1.0.0-id_sector'),
            number_client: localStorage.getItem('@sac-v1.0.0-number_client'),
            number_bot: localStorage.getItem('@sac-v1.0.0-number_bot'),
            message: item,
            conversation_situation: "EVALUATION"
        });
    })

}



export const SendUploadFile = async (messages, urlImagem, referenceImage) => {

    const {
        id_company,
        id_sector,
        number_bot,
        number_client,
    } = messages;

    await apiFecthPost("send-messages?sendFiles=true", {
        id_company,
        id_sector,
        number_client,
        number_bot,
        url: urlImagem,
        image_reference: referenceImage,
        message: "",
        conversation_situation: "ATTENDANCE"
    });
}

export const SendUploadFileGroup = async (messages, urlImagem, referenceImage) => {

    try {
        const {
            id_company,
            id_sector,
            id_group_external,
            number_client,
            number_bot,

        } = messages;

        const result = await apiFecthPost('send-message-group?sendFiles=true', {
            id_company,
            id_sector,
            id_group_external,
            number_client,
            number_bot,
            message: "",
            url: urlImagem
        });

        return result;
    } catch (error) {
        console.log(error)
    }


}

export const MessageAttendance = async (id_user) => {

    try {
        const response = await apiFetchGet(`get-message-attendance?id_attendance=${id_user}`);
        return response.data;
    } catch (error) {

        console.log(error);
    }
}

export const ReceivedMessages = async () => {
    try {

        const response = await apiFetchGet(
            `received-messages-mongo?id_company=${localStorage.getItem('@sac-v1.0.0-id_company')}&id_sector=${localStorage.getItem('@sac-v1.0.0-id_sector')}&number_client=${localStorage.getItem('@sac-v1.0.0-number_client')}
        `);

        return response.data
    } catch (error) {

        console.log(error);
    }
}


export const MessageAttendanceGroups = async (id_user) => {
    try {
        const response = await apiFetchGet(`get-message-group-attendance?id_attendance=${id_user}`);
        return response.data;
    } catch (error) {

        console.log(error);
    }
}


export const ReceivedMessagesGroups = async () => {
    try {

        const response = await apiFetchGet(
            `received-message-group-attendance?id_company=${localStorage.getItem('@sac-v1.0.0-id_company')}&id_sector=${localStorage.getItem('@sac-v1.0.0-id_sector')}&id_group=${localStorage.getItem('@sac-v1.0.0-id_group_external')}
        `);

        return response.data
    } catch (error) {

        console.log(error);
    }
}