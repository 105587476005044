import React, { Fragment } from 'react';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import { connect } from 'react-redux';
import {useSelector} from 'react-redux';
import {userSelector} from '../../store/modules/login/selectors';

const HeaderLogo =({sidebarToggle, sidebarHover}) => {

  const user     = useSelector(userSelector);
 
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box className="header-logo-wrapper">
            <Box className="header-logo-text" style={{fontSize:'12px'}}>{user.name_company}</Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);
