import React, {
  Fragment,
  useState,
  useEffect
} from 'react';
import clsx from 'clsx';
import {
  Hidden,
  IconButton,
  AppBar,
  Box,
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  setSidebarToggle,
  setSidebarToggleMobile
} from '../../reducers/ThemeOptions';
import HeaderLogo from '../../layout-components/HeaderLogo';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import HeaderDots from '../HeaderDots';
import HeaderDotsUra from '../HeaderDotsUra';
import { apiFetchGet } from '../../Helpers/RequestResponse';
import { useSelector } from 'react-redux';
import { userSelector } from '../../store/modules/login/selectors';
import socket from '../../config/socket'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'
import {
  MessageAttendance,
} from '../../Helpers/Api';
import soundAlert from '../../assets/audio/alerta.wav';
import soundInfornet from '../../assets/audio/MSN.mp3';


const Header = props => {

  const user = useSelector(userSelector);
  const toggleSidebar = () => setSidebarToggle(!sidebarToggle);
  const toggleSidebarMobile = () => setSidebarToggleMobile(!sidebarToggleMobile);

  const newAudio = new Audio(soundAlert);
  const infornetAudio = new Audio(soundInfornet);

  const {
    headerShadow,
    headerFixed,
    sidebarToggleMobile,
    setSidebarToggleMobile,
    setSidebarToggle,
    sidebarToggle,
  } = props;

  const [state, setState] = useState({
    vertical: 'bottom',
    horizontal: 'right',
  });

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { vertical, horizontal } = state;

  const handleCloseShowAlert = () => setShowAlert(false)
  
  
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [value, setValue] = useState(0);
  const [countUra, setCountUra] = useState(0);
  const [dataUra, setDataUra] = useState([]);
  const [showAlert, setShowAlert] = useState(false)
  const [dataSocket, setDataSocket] = useState({});
  const fetch = async () => {
    try {
      const result = await apiFetchGet('scrapupdate?active=true');
      setCount(result.data.count);
      setData(result.data.rows);

    } catch (error) {
      console.log(error);
    }
  }

  const fecthUra = async () => {
    try {
      const result = await apiFetchGet(`get-message-ura-attendance?id_attendance=${user.id}`)
      setCountUra(result.data.length);
      setDataUra(result.data)
    } catch (error) {
      console.log(error)
    }
  }


  const socketInstance = () => {
    socket.on(`${user.id}`, data => {

      const isAlertSound = data.group_type || false
 

       if(data.group_type && data.message_notify !== null){
        setDataSocket(data)
        setShowAlert(true)
       }

       if(!data.group_type){
        message_attendence(isAlertSound);
       }
       
    });
  }

  let isAudioPlayed = false;
  const playAudio = async (audioFile) => {
    if (!isAudioPlayed) {
      isAudioPlayed = true;
  
      try {
        await audioFile.play();
      } finally {
        setTimeout(() => {
          isAudioPlayed = false;
        }, 7000); 
      }
    } else {
      console.log("Audio has already been played during this session.");
    }
  };


  const message_attendence = async (isAlertSound) => {
    try {
      const response = await MessageAttendance(user.id);
    
      if (!isAlertSound) {
        if (!isAudioPlayed && response[0] && !response[0].message_read_attendance) {
          if (response[0].id_company === '5475190e-0681-4f7a-ab6a-76312f465527') {
            
            await playAudio(infornetAudio);
          } else {
            await playAudio(newAudio);
          }
        }
      }
  
    } catch (error) {
      console.error("Error in message_attendence:", error);
    }
  };

  useEffect(() => {


    const interval = setInterval(() => {
      setValue(value + 1);

      if (value < 2) {
        fetch();
      }

      if (value === 60 || value === 90 || value === 120) {
        fetch();
      }

      if (value > 140) {
        setValue(0)
      }
    }, 5000);
    return () => clearInterval(interval);

  }, [value]);

  useEffect(() => {
    fecthUra()
    const interval = setInterval(() => {
      fecthUra()
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    socketInstance()
    message_attendence()
  },[])


  return (
    <Fragment>
      <AppBar
        color="secondary"
        className={clsx('app-header', {
          'app-header-collapsed-sidebar': props.isCollapsedLayout
        })}
        position={headerFixed ? 'fixed' : 'absolute'}
        elevation={headerShadow ? 11 : 3}>
        {!props.isCollapsedLayout && <HeaderLogo />}
        <div onClick={handleCloseShowAlert}>
          <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={showAlert}
            key={vertical + horizontal}
            autoHideDuration={6}
          >
              <Alert severity="warning">{`${dataSocket.message_notify}`}</Alert>
          </Snackbar>
        </div>
        
        <Box className="app-header-toolbar">
          <Hidden lgUp>
            <Box
              className="app-logo-wrapper"
              title="React Admin Dashboard with Material-UI PRO">

              <Hidden smDown>

                <Box className="app-logo-text">{user.login_user}</Box>
              </Hidden>
            </Box>
          </Hidden>
          <Hidden mdDown>
            <Box className="d-flex align-items-center">
              {!props.isCollapsedLayout && (
                <Box
                  className={clsx('btn-toggle-collapse', {
                    'btn-toggle-collapse-closed': sidebarToggle
                  })}>
                  <Tooltip title="Toggle Sidebar" placement="right">
                    <IconButton
                      color="inherit"
                      onClick={toggleSidebar}
                      size="medium"
                      className="btn-inverse">
                      {sidebarToggle ? (
                        <MenuRoundedIcon />
                      ) : (
                        <MenuOpenRoundedIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              )}


            </Box>
          </Hidden>
          <Box className="d-flex align-items-center">

          {user.profile_attendance && user.id_profile === 'e3861690-d695-426e-a6bd-870e4728c4b0' && 
            window.location.href.endsWith('#/chat') && (
              <a href='#/Evaluation' style={{fontSize:16, color:'#fff', fontWeight:'bold', border:'1px solid #fff', padding:10}}>Menu</a>
            )
          }

          {user.profile_attendance && user.id_profile === 'e3861690-d695-426e-a6bd-870e4728c4b0' && 
            window.location.href.endsWith('#/Evaluation') && (
              <a href='#/chat' style={{fontSize:16, color:'#fff', fontWeight:'bold', border:'1px solid #fff', padding:10}}>Chat</a>
            )
          }
           

            <HeaderDots {...{ count, data }} />
            {user.profile_attendance &&
              <HeaderDotsUra {...{ countUra, dataUra, fecthUra }} />
            }

            <HeaderUserbox />
            <Box className="toggle-sidebar-btn-mobile">
              <Tooltip title="Toggle Sidebar" placement="right">
                <IconButton
                  color="inherit"
                  onClick={toggleSidebarMobile}
                  size="medium">
                  {sidebarToggleMobile ? (
                    <MenuOpenRoundedIcon />
                  ) : (
                    <MenuRoundedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        </Box>
      </AppBar>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggle: state.ThemeOptions.sidebarToggle
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
