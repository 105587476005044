import React, {
  Fragment,
  useState
} from 'react';
import {
  Grid,
  Container,
  Button,
  TextField
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TabPanel from './TabPanel';
import AlertWarning from '../../Notifications/Warning';
import { loginAction } from '../../../store/modules/login/actions';
import {
  errosSelector,
  successSelector,
  tokenSelector,
  userSelector,
  twoSelector
} from '../../../store/modules/login/selectors';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import './style.css';


const LivePreviewExample = () => {

  const history = useHistory();

  const dispatch = useDispatch();

  const baseUrlAws = "https://pwrbot.s3-sa-east-1.amazonaws.com/uploads/images/";

  const errors = useSelector(errosSelector);
  const success = useSelector(successSelector);
  const isToken = useSelector(tokenSelector);
  const user = useSelector(userSelector);
  const two = useSelector(twoSelector);

  const { profile_attendance, first_access, profile_panel } = user;

  const [form, setForm] = useState({
    login_user: '',
    password: '',
    active: true
  });

  const changeValue = field => evt => {
    setForm({
      ...form,
      [field]: evt.target.value
    })
  };

  const sendKeyPress = async (ev) => {
    if (ev.key === 'Enter' && form.login_user && form.password) login();
  };

  const login = () => dispatch(loginAction(form));

  if (!two) {

    if (success && isToken && !profile_attendance && !first_access && !profile_panel) {
      history.push('/Dashboard');
    }

    if (success && isToken && profile_attendance && !first_access) {
      history.push('/chat');
    }

    if (success && isToken && profile_panel && !first_access) {
      history.push('/statistic');
    }

    if (success && isToken && first_access) {
      history.push('/PagesPassword');
    }
  } else {
    history.push('/TwoFA');
  }

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid item md={8} lg={7}
                      className="d-flex align-items-center">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-plum-plate min-vh-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{ backgroundImage: `url(${baseUrlAws}efa77c937b6302b6838bd0993bbaa9c6.jpg)` }}
                          />
                          <div className="bg-composed-wrapper--bg  opacity-5" />
                        </div>
                      </div>
                    </Grid>

                    <Grid item xs={12} md={4} lg={5} className="d-flex align-items-center">
                      <Container maxWidth="sm">
                        <Grid item md={8}>
                          {errors && <AlertWarning message={errors} />}
                        </Grid>
                        <TabPanel>
                        

                          <div className="display-4 mb-2 ">
                            <img className="img" src={`${baseUrlAws}baccfd1adbde1b9f85769fc1bcc83969.jpg` } alt="Logo" />
                          </div>

                          <div className="mb-3">
                            <TextField
                              required
                              autoFocus
                              autoComplete="off"
                              aria-required="true"
                              onChange={changeValue('login_user')}
                              variant="outlined"
                              label="Usuário"
                              fullWidth
                              placeholder="Usuário"
                              type="text"
                            />
                          </div>
                          <div className="mb-3">
                            <TextField
                              required
                              autoComplete="off"
                              aria-required="true"
                              onChange={changeValue('password')}
                              variant="outlined"
                              label="Senha"
                              fullWidth
                              placeholder="Senha"
                              type="password"
                              onKeyPress={sendKeyPress}
                            />
                          </div>

                          <Button
                            onClick={login}
                            disabled={!form.login_user || !form.password ? true : false}
                            color="primary"
                            size="large"
                            variant="contained"
                            className="mb-5 bntWidth"
                          >

                            Entrar
                          </Button>

                        </TabPanel>
                      </Container>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default LivePreviewExample;