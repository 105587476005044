import React from 'react';
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle 
} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({open,handleClose,salvar, duplicar, children, title, loading, exibeButton = true,bkId,}) => {
    return(
        <Dialog 
            open={open} 
            onClose={handleClose} 
            aria-labelledby="form-dialog-title" 
            fullWidth={true} 
            maxWidth={"lg"}
        >
            <DialogTitle id="form-dialog-title"> {title ? title: 'Novo Cadastro'} </DialogTitle>
                <DialogContent>
                    {children}
                </DialogContent>
            <DialogActions>
                {exibeButton && !bkId &&
                    <Button 
                        onClick={salvar}
                        disabled={loading ? true : false}
                        variant="contained" 
                        color="primary">  {loading ? <CircularProgress color="secondary"/> : 'Salvar'}
                    </Button>
                }
                {bkId &&
                  <Button 
                    onClick={duplicar} 
                    variant="contained" 
                    color="primary">  {loading ? <CircularProgress style={{color:'red'}}/> : 'Duplicar'} 
                  </Button>
                }  
                <Button 
                    variant="contained" 
                    onClick={handleClose} 
                    color="secondary">
                        X
                </Button>
        </DialogActions>
      </Dialog>
    )
}