import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  IconButton,
  Box,
  Typography,
  Tabs,
  Tab,
  Popover,
  Divider,
  Tooltip,
  Button
} from '@material-ui/core';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import { formatDate } from '../../Helpers/Functios';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Modal from '../../example-components/Modal';
import YouTubeIcon from '@material-ui/icons/YouTube';
import VideoLibraryOutlinedIcon from '@material-ui/icons/VideoLibraryOutlined';

function TabPanel({ children, value, index, ...other }) {

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


export default function HeaderDots({ count, data }) {

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState('Recado');
  const [texto, setTexto] = useState('');
  const [link, setLink] = useState([]);

  let exibeButton = false;

  const handleClick1 = event => setAnchorEl1(event.currentTarget);
  const handleClose1 = () => setAnchorEl1(null);

  const open1 = Boolean(anchorEl1);

  const handleView = (item) => {
    const { body } = item;
    parseUrl(body)
    setOpen(true);
    setTexto(body)
  }

  const handleClose = () => {
    setAnchorEl1(false);
    setOpen(false);
    setTexto('');
    setLink([]);
  }


  function parseUrl(item) {

    let str = item;
    let regexp = /(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,}/gi;
    let matches_array = str.match(regexp);

    setLink(matches_array);
  }

  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
          <Box component="span" pr="2">
            <IconButton onClick={handleClick1} color="inherit" className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-warning badge-header">{count}</div>
              <NotificationsActiveTwoToneIcon />
            </IconButton>
            <Popover open={open1} anchorEl={anchorEl1} onClose={handleClose1}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              classes={{ paper: 'app-header-dots' }}>
              <div className="popover-custom-xl overflow-hidden">
                <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                  <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                  <div className="bg-composed-wrapper--content text-light px-2 py-4">
                    <h4 className="font-size-xl font-weight-bold mb-2">Notificações </h4>
                    <p className="opacity-8 mb-0">
                      você tem <b className="text-success">{count}</b> {count > 1 ? 'novos recados' : 'novo recado'}
                    </p>
                  </div>
                </div>
                <div className="mx-2">
                  <Tabs value={0} indicatorColor="primary" textColor="primary" variant="fullWidth" >
                    <Tab className="py-3" label="Linha do tempo" />
                  </Tabs>
                </div>
                <div className="height-280">
                  <PerfectScrollbar>
                    <TabPanel value={0} index={0}>

                      {data.map((item) => {

                        return (
                          <>
                            <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                              <div className="timeline-item">
                                <h4 className="timeline-item-offset mb-2" style={{ fontSize: '9px' }}>{formatDate(item.createdAt)}</h4>
                                <div className="timeline-item--content">
                                  <div className="timeline-item--icon"></div>
                                  <h4 className="timeline-item--label mb-2 font-weight-bold"> {item.title}</h4>

                                  <Tooltip arrow title="Clique aqui para visualizar o conteúdo!" placement="right">
                                    <VisibilityIcon onClick={() => handleView(item)} />
                                  </Tooltip>
                                </div>
                              </div>
                            </div>
                          </>
                        )
                      })}
                    </TabPanel>
                  </PerfectScrollbar>
                </div>
                <Divider />
              </div>
            </Popover>
          </Box>

        <Modal {...{ open, handleClose, title, exibeButton }}>
          <p style={{ whiteSpace: 'pre' }} className="texto">{texto}</p>

          {link.map((i) => {

            let resultado = i.substring(8, 13);

            return (
              <>
                {resultado === 'youtu' &&
                  <>
                    <Tooltip arrow title="Assistir conteúdo" >
                      <Button variant="outlined" color="primary" href={`${i}`} target="_blank" style={{ fontSize: '20px', margin: '10px' }}>
                        <YouTubeIcon style={{ fontSize: '35px', color: 'red' }} /> ASSISTIR
                      </Button>
                    </Tooltip>
                  </>
                }

                {resultado === 'drive' &&
                  <>
                    <Tooltip arrow title="Assistir conteúdo" >
                      <Button variant="outlined" color="primary" href={`${i}`} target="_blank" style={{ fontSize: '20px', margin: '10px' }}>
                        <VideoLibraryOutlinedIcon style={{ fontSize: '35px', color: 'green' }} /> ASSISTIR
                      </Button>
                    </Tooltip>
                  </>
                }

              </>
            )
          })}
        </Modal>
      </div>
    </Fragment>
  );
}
