import React, { 
    Fragment,
    useState 
} from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    IconButton,
    Box,
    Typography,
    Tabs,
    Tab,
    Popover,
    Divider,
    Tooltip
} from '@material-ui/core';
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined';
import {formatDate} from '../../Helpers/Functios';
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import {apiFecthPost} from '../../Helpers/RequestResponse';
import { Alert } from 'Helpers/Functios';
import './style.css';

function TabPanel({children, value, index, ...other}) {

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};


export default function HeaderDots({countUra,dataUra,fecthUra}) {

  const [anchorEl1, setAnchorEl1] = useState(null);

  const handleClick1 = event => setAnchorEl1(event.currentTarget);
  const handleClose1 = () => setAnchorEl1(null);
  const [disabled,setDisabled] = useState(false);

  const open1 = Boolean(anchorEl1);

  const handleSendUra = async (number_client, number_bot) => {

    try{

        setDisabled(true);
        await apiFecthPost('send-messages-ura',{
            number_client,
            number_bot
        });

        Alert(
          'success', 
          'Atenção!',
          'Conversa resgatada com sucesso!'
        );
        
        fecthUra()
        handleClose1();
        setDisabled(false)
       
    }catch(error){
        console.log(error);
        console.log(error.response)
        setDisabled(false)
    }
  } 
    
  return (
    <Fragment>
      <div className="d-flex align-items-center popover-header-wrapper">
          <Box component="span" pr="2">
            <IconButton onClick={handleClick1} color="inherit"  className="btn-inverse mx-1 d-50">
              <div className="badge badge-pill badge-danger badge-header">{countUra}</div>
              <FeedbackOutlinedIcon className={`${countUra > 0 ? 'text-danger': ''}`} />
            </IconButton>
            <Popover 
                open={open1} 
                anchorEl={anchorEl1} 
                onClose={handleClose1}
                anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                transformOrigin={{vertical: 'top',horizontal: 'center'}}
                classes={{paper: 'app-header-dots'
            }}>
              <div className="popover-custom-xl overflow-hidden">
                <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2">
                  <div className="bg-composed-img-3 bg-composed-wrapper--image"></div>
                  <div className="bg-composed-wrapper--content text-light px-2 py-4">
                    <h4 className="font-size-xl font-weight-bold mb-2">
                        CONTATOS NA URA
                    </h4>
                    <p className="opacity-8 mb-0">
                       Ha <b className="text-white">{countUra}</b> {countUra > 1 ? 'novos contatos na URA' : 'contato na URA'} 
                    </p>
                  </div>
                </div>
                <div className="mx-2">
                <Tabs 
                    value={0} 
                    indicatorColor="primary" 
                    textColor="primary"  
                    variant="fullWidth" 
                >
                    <Tab className="py-3" label={`${ countUra <= 0 ? 'Não ha Contatos': 'Contatos'}`} />
                </Tabs>
                </div>
                <div className="height-280">

                  {countUra > 0 &&
                    <PerfectScrollbar>
                      <TabPanel value={0} index={0}>

                        {dataUra.map(({number_bot,number_client,updatedAt,name_company}) => {

                          return(
                            <>
                                <div className="timeline-list timeline-list-offset timeline-list-offset-dot">
                                  <div className="timeline-item">
                                      <p  className="timeline-item-text">
                                        {`Cliente: ${number_client}`}
                                      </p>
                                      <p  className="timeline-item-text">
                                        {`Boot: ${number_bot}`}
                                      </p>
                                      <p  className="timeline-item-text">
                                        {`Data: ${formatDate(updatedAt)}`}
                                      </p>
                                      <p  className="timeline-item-text">
                                        {`Empresa: ${name_company}`}
                                      </p>
                                      <div className="timeline-item--content">
                                        <div className="timeline-item--icon"></div>
                                        <button className="button-tooltip" disabled={disabled}> 
                                          <Tooltip arrow title="Clique aqui para resgatar a conversa" placement="right">
                                                <PlaylistAddCheckOutlinedIcon 
                                                    className="Playlist"
                                                    onClick={() => handleSendUra(number_client, number_bot)}
                                                />
                                            </Tooltip>
                                        </button>
                                      </div>
                                  </div>
                                </div>
                              </>
                          )
                        })}
                      </TabPanel>
                    </PerfectScrollbar>
                  }
                </div>
                <Divider />
              </div>
            </Popover>
          </Box>
      </div>
    </Fragment>
  );
}