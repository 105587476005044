import types from '../constants/authTypes';

export const loginAction = credentials => {
    return {
        type: types.LOGIN_SAGA,
        payload: credentials
    };
}

export const clearAction = () => {
    return {
        type: types.CLEAR_AUTH
    };
};

export const logoutAction = () => {
    return {
        type: types.LOGOUT
    };
};