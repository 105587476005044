import types from '../constants/authTypes';

const initial_state = {
    success: false,
    errors: null,
    token: null,
    user: {},
    twofa:false
}

export default function loginReducer(state = initial_state, action) {

    switch (action.type) {
        
        case types.LOGIN_SUCCESS:
            return {
                ...state,
                success: true,
                errors: null,
                token: action.payload.token,
                user: action.payload.user,
                twofa: action.payload.extra_validation_2fas
            };
        case types.LOGIN_FAILURE:
            return {
                ...state,
                success: false,
                errors: action.payload
            };
       
        case types.CLEAR_AUTH:
            return initial_state;
        case types.LOGOUT:
            return initial_state;
        default:
            return state;
    }
}