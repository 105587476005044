import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation, HashRouter } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import { LeftSidebar,  MinimalLayout,CollapsedSidebar, } from './layout-blueprints';
import PagesRegister from './example-pages/PagesRegister';
import PagesPassword from './example-pages/PagesRegister/password.js';
import PagesError404 from './example-pages/PagesError404';
import PagesError500 from './example-pages/PagesError500';
import PagesError505 from './example-pages/PagesError505';
import SuspenseLoading from './SuspenseLoading';
import {useSelector} from 'react-redux';
import {userSelector,successSelector,twoSelector} from './store/modules/login/selectors';

const Company     = lazy(() => import('./Pages/Registrations/Company/ListData'));
const Profile     = lazy(() => import('./Pages/Registrations/Profile/ListData'));
const Ura         = lazy(() => import('./Pages/Registrations/Uras/ListData'));
const User        = lazy(() => import('./Pages/Registrations/User/ListData'));
const Sector      = lazy(() => import('./Pages/Registrations/Sector/ListData'));
const Dashboard   = lazy(() => import('./Pages/Dashboard'));
const Firing      = lazy(() => import('./Pages/Registrations/Firing/ListData'));
const Keyword     = lazy(() => import('./Pages/Registrations/Keyword/ListData'));
const StrapUpdate = lazy(() => import('./Pages/Registrations/ScrapUpdate/ListData'));
const TwoFA       = lazy(() => import('./Pages/2fa'));
const Stops       = lazy(() => import('./Pages/Registrations/Stops/ListData'));
const Holiday     = lazy(() => import('./Pages/Registrations/Holliday/ListData'));
const ApplicationChat = lazy(() => import('./Pages/ApplicationChat'));
const ApplicationChatGroups = lazy(() => import('./Pages/ApplicationChatGroups'));
const GlobalParameters = lazy(() => import('./Pages/Registrations/GlobalParameters/ListData'));
const Contacts    = lazy(() => import('./Pages/Registrations/Contatcs/ListData'));
const Integration = lazy(() => import('./Pages/Registrations/Integration/ListData'));
const Groups      = lazy(() => import('./Pages/Registrations/Group/ListData'));

const ReportMessages   = lazy(() => import('./Pages/Reports/Messages'));
const ReportAttendence = lazy(() => import('./Pages/Reports/Attendences'));
const ReportAgents     = lazy(() => import('./Pages/Reports/PerformanceAgents'));
const Painel           = lazy(() => import('./Pages/Reports/PainelStatisticas'));
const Evaluation       =  lazy(() => import('./Pages/Reports/Evaluation/ListData'));

const Routes = () => {

  const user     = useSelector(userSelector);
  const two      = useSelector(twoSelector);
  const acesso   = useSelector(successSelector);
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };
  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  return (
    <HashRouter>
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            {user.first_access 
                ? 
              <Redirect exact from="/" to="/PagesPassword" />  
                :  
              <Redirect exact from="/" to="/PagesRegister" /> 
            }
            {two 
                ? 
              <Redirect exact from="/" to="/TwoFA" />  
                :  
              <Redirect exact from="/" to="/PagesRegister" /> 
            } 
            <Route path={[
              '/PagesRegister',
              '/PagesPassword',
              '/PagesError404',
              '/PagesError500',
              '/PagesError505',
              '/TwoFA'
            ]}>

              <MinimalLayout>

                <Switch 
                  location={location} 
                  key={location.pathname}
                >
                  <motion.div 
                    initial="initial" 
                    animate="in" 
                    exit="out" 
                    variants={pageVariants}  
                    transition={pageTransition}
                  >
                    <Route path="/PagesRegister" component={PagesRegister} />
                    <Route path="/PagesPassword" component={PagesPassword} />
                    <Route path="/TwoFA" component={TwoFA} />
                    <Route path="/PagesError404" component={PagesError404} />
                    <Route path="/PagesError500" component={PagesError500} />
                    <Route path="/PagesError505" component={PagesError505} />

                    {acesso &&  
                      <Redirect exact from="/" to="/PagesRegister" />
                    }

                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>



            <Route path={[
              '/chat',
              '/chatgroups'
            ]}>

              

            <CollapsedSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>

                  {acesso && user.profile_attendance ? (
                      <>
                        <Route path="/chat" component={ApplicationChat} />
                        <Route path="/chatgroups" component={ApplicationChatGroups} />
                      </>
        ) : (
          <Redirect exact from="/" to="/PagesRegister" />
        )}
        
                  </motion.div>
                </Switch>
              </CollapsedSidebar>
            </Route>

            <Route path={[
              '/Company',
              '/Profile',
              '/Ura',
              '/User',
              '/Sector',
              '/ReportMessages',
              '/ReportAttendence',
              '/ReportAgents',
              '/Dashboard',
              '/Keyword',
              '/Send',
              '/Recados',
              '/Painel',
              '/Paradas',
              '/Feriados',
              '/GlobalParameters',
              '/Contacts',
              '/Integrations',
              '/Groups',
              '/Evaluation'
            ]}>

              {acesso && !user.profile_attendance && !user.profile_panel &&
                <LeftSidebar restrict >
                  <Switch location={location} key={location.pathname}>
                    <motion.div 
                      initial="initial" 
                      animate="in" 
                      exit="out"  
                      variants={pageVariants} 
                      transition={pageTransition}
                    >
                      <Route path="/Company" component={Company}/>
                      <Route path="/Profile" component={Profile}/>
                      <Route path="/Ura" component={Ura}/>
                      <Route path="/User" component={User}/>
                      <Route path="/Sector" component={Sector} />
                      <Route path="/ReportMessages" component={ReportMessages} />
                      <Route path="/ReportAttendence" component={ReportAttendence} />
                      <Route path="/ReportAgents" component={ReportAgents} />
                      <Route path="/Dashboard" component={Dashboard} />
                      <Route path="/Send" component={Firing} />
                      <Route path="/Keyword" component={Keyword} />
                      <Route path="/Recados" component={StrapUpdate} />
                      <Route path="/Painel" component={Painel} />
                      <Route path="/Paradas" component={Stops} />
                      <Route path="/Feriados" component={Holiday} />
                      <Route path="/GlobalParameters" component={GlobalParameters} />
                      <Route path="/Contacts" component={Contacts} />
                      <Route path="/Integrations" component={Integration} />
                      <Route path="/Groups" component={Groups} />
                      <Route path="/Evaluation" component={Evaluation} />
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              }

              

              {acesso && user.profile_attendance && !user.profile_panel && user.id_profile === "e3861690-d695-426e-a6bd-870e4728c4b0" && 
                <LeftSidebar restrict >
                  <Switch location={location} key={location.pathname}>
                    <motion.div 
                      initial="initial" 
                      animate="in" 
                      exit="out"  
                      variants={pageVariants} 
                      transition={pageTransition}
                    >
                      <Route path="/Company" component={Company}/>
                      <Route path="/Profile" component={Profile}/>
                      <Route path="/Ura" component={Ura}/>
                      <Route path="/User" component={User}/>
                      <Route path="/Sector" component={Sector} />
                      <Route path="/ReportMessages" component={ReportMessages} />
                      <Route path="/ReportAttendence" component={ReportAttendence} />
                      <Route path="/ReportAgents" component={ReportAgents} />
                      <Route path="/Dashboard" component={Dashboard} />
                      <Route path="/Send" component={Firing} />
                      <Route path="/Keyword" component={Keyword} />
                      <Route path="/Recados" component={StrapUpdate} />
                      <Route path="/Painel" component={Painel} />
                      <Route path="/Paradas" component={Stops} />
                      <Route path="/Feriados" component={Holiday} />
                      <Route path="/GlobalParameters" component={GlobalParameters} />
                      <Route path="/Contacts" component={Contacts} />
                      <Route path="/Integrations" component={Integration} />
                      <Route path="/Groups" component={Groups} />
                      <Route path="/Evaluation" component={Evaluation} />
                    </motion.div>
                  </Switch>
                </LeftSidebar>
              }
              
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
    </HashRouter>
  );
};
export default Routes;