import React, { Fragment,useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Avatar,Box,Menu,Button,List,ListItem,Tooltip,Divider} from '@material-ui/core';
import {StyledBadge} from './styled';
import {logout} from '../../Helpers/RequestResponse';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {useSelector} from 'react-redux';
import {userSelector} from '../../store/modules/login/selectors';

export default function HeaderUserbox() {

  const user     = useSelector(userSelector);


  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => setAnchorEl(event.currentTarget);
  const handleClose = () =>  setAnchorEl(null);

  const handleLogout = () => logout();
  

  return (
    <Fragment>
      <Button color="inherit"  onClick={handleClick}  className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <Box>
          <StyledBadge overlap="circle" anchorOrigin={{vertical: 'bottom',horizontal: 'right'}} variant="dot">
            <Avatar sizes="44" alt="Dustin Watson" src="" />
          </StyledBadge>
        </Box>
        <div className="d-none d-xl-block pl-3">
          <div className="font-weight-bold pt-2 line-height-1">
              {user.name_user}
          </div>
      
        </div>
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu anchorEl={anchorEl} keepMounted getContentAnchorEl={null} open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        onClose={handleClose}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <Box>
              <StyledBadge overlap="circle"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                variant="dot">
                <Avatar sizes="44" alt="Dustin Watson" src="" />
              </StyledBadge>
            </Box>
            <div className="pl-3 ">
              <div className="font-weight-bold text-center pt-2 line-height-1">
                {user.name_user}
              </div>
              <span className="text-black-50 text-center">
              {user.login_user}
              </span>
            </div>
            <Divider className="w-100 mt-2" />
            <ListItem button>Minha Conta</ListItem>
           
            <Divider className="w-100" />
            <Divider className="w-100" />
            <ListItem className="d-block rounded-bottom px-3 pt-3 pb-0 text-center">
              <Tooltip arrow title="Sair">
                <Button color="default" className="text-twitter" onClick={handleLogout}>
                  <span className="btn-wrapper--icon">
                    <ExitToAppIcon />
                  </span>
                </Button>
              </Tooltip>
            </ListItem>
          </List>
        </div>
      </Menu>
    </Fragment>
  );
}
