import { 
    put, 
    call, 
    takeLatest 
} from 'redux-saga/effects';
import { apiFecthPost } from '../../../Helpers/RequestResponse';
import types from '../constants/authTypes';

export function* loginSaga(action) {
    try {
      
        const {data} = yield call(apiFecthPost,  `${action.payload.active === false ? 'session2fa' : 'session'}`, action.payload);
        yield put({ 
            type: types.LOGIN_SUCCESS, 
            payload: data 
        });
    } catch (error) {
     
        if (error.response) {
            if(error.response.data.errors[0])
                yield put({ 
                  type: types.LOGIN_FAILURE 
                });
                yield put({ 
                    type: types.LOGIN_FAILURE, 
                    payload: error.response.data.errors[0].message 
                });

        } else if(error.request) {
            yield put({ 
                type: types.LOGIN_FAILURE 
            });
            yield put({ 
                type: types.LOGIN_FAILURE, payload: 'Problemas de conexão.' 
            });
        }
    }
}

export default function* watcher() {
    yield takeLatest(types.LOGIN_SAGA, loginSaga);
}